@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

body {
  background-color: #000000;
}

strong, b, .font-bold {
  color: #258BEA;
}

.box-container {
  border-image: linear-gradient(to bottom, #707070, transparent);
  border-image-slice: 1;
  border-width: 2px;
  border-style: solid;
}

.bg-black-gradient {
  background: var(--black-gradient);
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}
.bg-player-search-gradient {
  background: linear-gradient(90deg, #00040f 0%,#1a1a1a 25%, #1a1a1a 75%, #00040f 100%);
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: var(--card-shadow);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.relative.card-clicked::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
  z-index: -1;
}

.feature-card {
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(144, 61, 61);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.feature-card:hover::before {
  transform: scaleX(1);
  transition: transform 0.3s ease-in-out;
}

.loader {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: #FFFFFF;
  border-bottom-color: #FFFFFF;
}

@keyframes pillGrow {
  from {
      max-width: 50px;
  }
  to {
      max-width: 1000px; /* Adjust based on your maximum expected width */
  }
}

.animate-pillGrow {
  animation: pillGrow 4.1s forwards;
  overflow: hidden;
}

.metal-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, gray, darkgray);
}

.blue-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #258BEA, #258BEA);
}

.glow-on-hover {
  transition: box-shadow 0.3s ease-out, transform 0.2s ease;
  box-shadow: none;  
  background-color: transparent;  
  border-radius: 999px;  /* Add the same border-radius as the enclosing div */
}

.glow-on-hover:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.9);
  transform: scale(1.05);
}


@keyframes fadeInAndPulse {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*
.rectangle-box-animate {
  animation: fadeInAndPulse 2s forwards;
}
*/


.rectangle-box {
  border: 1px solid transparent;
  background: 
  linear-gradient(#000000, #000000) padding-box,
  linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.491) 30%, rgba(255, 255, 255, 0) 80%) border-box;
  border-radius: 10%;
}

.rectangle-payment {
  position: relative; /* Add this line */
  width: 20rem; /* Adjust width as needed */
  height: 12rem; /* Adjust height as needed */
  padding: 1.5rem; /* Adjust padding as needed */
  border: 1px solid transparent;
  background: linear-gradient(#000000, #000000) padding-box, #258BEA border-box;
  border-radius: 10%;
  text-align: center;
}

.rectangle-benefits {
  border: 1px solid transparent;
  background: 
    linear-gradient(#000000, #000000) padding-box, 
    linear-gradient(180deg, #258BEA, #000000) border-box; /* Gradient for border */
  border-radius: 10%;
}

.rectangle-example {
  border: 1px solid transparent;
  background: 
  linear-gradient(#000000, #000000) padding-box,
  linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.491) 40%, rgba(255, 255, 255, 0) 80%) border-box;
  border-radius: 30px;
}

.rectangle-divider {
  position: relative;
  border-bottom: 0.5px solid white; /* Reduce border width to make it thinner */
  background: #000; /* Making the background black */
  border-radius: 30px;
  overflow: hidden;
}

.valorant-divider {
  position: relative;
  border-top: 0.5px solid white; /* Reduced border width */
  background: #000;
  border-radius: 30px;
  overflow: hidden;
}


.triangle-icon {
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(255, 255, 255);
  transition: transform 0.3s;
  transform: rotate(90deg);
}

.triangle-icon-rotate {
  transform: rotate(180deg);
}

.valorant-faq-box {
  border: 2px solid transparent;
  background: 
    linear-gradient(#000000, #000000) padding-box,
    linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.491) 30%, rgba(255, 255, 255, 0) 80%) border-box;
  border-radius: 35px;
  width: 90%; 
  height: 340px;
  margin-bottom: 2rem; /* Adds space at the bottom */
  display: flex;
  justify-content: center; 
  padding: 1rem;
}

.text-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: top;
  padding-top: 5%;
  width: 90%;
}

.faq-question {
  color: #FFFFFF;
  font-size: 22px;  /* Adjust this value for the desired size of the main text */
}

.faq-answer {
  color: #cbc7c7;
  margin-left: 35px; 
  font-size: 19px; 
}

.faq-item {
    display: flex;
    flex-direction: column;
}

.valorant-search-box {
  border: 1px solid transparent;
  background: 
    linear-gradient(#000000, #000000) padding-box,
    linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.491) 30%, rgba(255, 255, 255, 0) 80%) border-box;
  border-radius: 40px; /* Example radius, adjust as needed */
}

.features-container {
  position: relative;
  border: 2px solid transparent;
  border-top-color: #6C6D6F;
  background: transparent;
  border-radius: 20px;
  padding: 20px 40px;
  width: 800px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.features-heading {
  font-size: 70px;
  text-align: center;
}

.features-paragraph {
  text-align: center;
  max-width: 74%;
  margin: auto;
}


.selected-text {
  color: #ff4500; /* Or any color you want */
}

.hover-effect:hover {
  cursor: pointer;
  text-decoration: underline;
}

.left-column-content {
  padding-top: 10px;
}

.gradient-border-container {
  position: relative;
  flex-basis: 15%;
  flex-shrink: 0;
}

.gradient-border-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;  /* width of the border */
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.19) 40%, rgba(255, 255, 255, 0) 80%); /* example gradient */
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.shimmer-effect {
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(
    to right,
    #f6f7f8 4%,
    #edeef1 25%,
    #f6f7f8 36%
  );
  background-size: 800px 104px;
  position: relative;
}

.metal-gradient.shimmer-effect::before,
.blue-gradient.shimmer-effect::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: shimmer 2s infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s forwards;
}

.scale-on-hover:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

@keyframes fadeInAndRotate {
  from {
    opacity: 0;
    transform: translateY(50px) rotate(-5deg);
  }
  to {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }
}

.rectangle-box-animate {
  animation: fadeInAndRotate 2s forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 2s forwards;
}

.get-started-btn {
  background-color: #258BEA;  /* Blue color */
  color: black;               /* Text color */
  border: 1px solid white;    /* Border color */
}

.get-started-btn:hover {
  background-color: #258BEA;  /* Blue color on hover */
}

/* index.css */

.pricing-button {
  display: inline-block;
  padding: 8px 20px; /* Adjust as needed for size */
  background-color: #258BEA; /* Your specified blue */
  border: 2px solid white;
  border-radius: 50px; /* High value to create a pill shape */
  color: black;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s ease-in-out; /* Optional: Smooth transition effect for hover states */
  font-size: 40px;
}

.pricing-button:hover {
  background-color: #1C6FD0; /* Slightly darker blue on hover */
}

/* Global Red Rectangle Line Styles */
.red-border-left {
  position: fixed;
  top: 0;
  left: 0;
  width: 10px; /* You can adjust the width as needed */
  height: 100%;
  background-color: red;
  z-index: 1000; /* Ensures it's on top of other content */
}

.red-border-right {
  position: fixed;
  top: 0;
  right: 0;
  width: 10px; /* You can adjust the width as needed */
  height: 100%;
  background-color: red;
  z-index: 1000; /* Ensures it's on top of other content */
}

.content-container {
  position: relative;
}

