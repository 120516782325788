/* Default styles for larger screens */
.box-container {
  position: absolute;
  top: 21%; /* Changed from fixed pixel to percentage */
  right: 28%; /* Changed from fixed pixel to percentage */
}

.box {
  position: relative;
  width: 12vw; /* Changed to viewport width */
  height: 17vw; /* Adjusted based on aspect ratio */
  transform-style: preserve-3d;
  animation: animate 45s linear infinite;
  will-change: transform;
}

@keyframes animate {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.box span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform: rotateY(calc(var(--i) * 45deg)) translateZ(325px);
  -webkit-box-reflect: below 0px linear-gradient(transparent, transparent, #0004);
  will-change: transform;
}

.box span img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: transform;
}

/* Adjustments for mobile */
@media (max-width: 769px) {
  .box-container {
    display: none;
  }
}

/* Adjustments for tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .box-container {
    top: 25%; /* Adjust as needed for tablets */
    right: 30%; /* Adjust as needed for tablets */
  }

  .box {
    width: 18vw;
    height: 25vw;
  }
}

/* Adjustments for larger tablets and small desktops */
@media (min-width: 1025px) and (max-width: 1080px) {
  .box-container {
    top: 21%;
    right: 28%;
  }

  .box {
    width: 12vw;
    height: 17vw;
  }
}

/* Adjustments for 1440 and ultrawide */
@media (min-width: 1921px) and (max-width: 2560px) {
  .box-container {
    top: 15%;
    right: 35.5%;
  }

  .box {
    width: 8vw;
    height: 12vw;
  }
}